<template>
<div class="editTheme page">
  <headers title="更换主题" :key="activeIndex"></headers>
  <div class="scroll">
    <img :src="this.colorList[this.activeIndex].colorImg" alt="" class="img">
    <div class="floor">
      <div class="radioGroup flexSpaceBetween">
        <div class="item" v-for="(item,index) in colorList" :key="index" @click="selectColor(item, index)">
            <div class="color" :class="item.color">
              <van-icon name="success" class="active" v-if="activeIndex == index" size="24"/>
            </div>
            <div class="color-text">{{item.text}}</div>
      </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import headers from "@/components/headers.vue";

export default {
  name: "editTheme",
  components: {headers},
  data() {
    return {
      activeIndex: 0,
      colorImg: "",
      colorList: [
        {
          color: 'headerBgWhite',
          colorImg: require('/static/img/zhutu1.png'),
          text: '白色(默认)'
        },
        {
          color: 'headerBgRed',
          colorImg: require('/static/img/zhutu2.png'),
          text: '红色'
        },
        {
          color: 'headerBgBlack',
          colorImg: require('/static/img/zhutu3.png'),
          text: '黑色'
        },
        {
          color: 'headerBgBlue',
          colorImg: require('/static/img/zhutu4.png'),
          text: '蓝色'
        }
      ]
    }
  },
  mounted() {
    this.activeIndex = this.$store.getters['getThemeIndex']
  },
  methods: {
    selectColor(item,index){
      this.activeIndex = index;
      this.$store.commit('setThemeIndex', index)
    }
  }
}
</script>

<style scoped lang="scss">
.editTheme {
  height: 100%;
  background: #f7f7fe;
  .scroll {
    padding-top: 30px;
    .img {
      width:  264px;
      box-shadow: 0 0 0.26667rem 0.26667rem #e8f1fe;
    }
    .radioGroup {
      padding: 35px 15px 20px;
      .item {
        .color {
            position: relative;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
           .active {
              color: #fff;

            }
        }
        &:first-child {
          .active {
            color: #1989fa;
          }
        }
      }
    }
  }
}
</style>